import {  HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { environment } from '@environments/environment';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const hostName = environment.api_server;;
  const request2 = req.clone({
      withCredentials: req.url.indexOf(hostName) > -1
  });
  return next(request2).pipe(
    catchError((error: any) => {
      if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
      } else {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      return throwError('System encountered an error; please try again later.');
    })
  );;
};


