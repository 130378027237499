import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';

import { PWAModule } from '@core/pwa';
import { StateModule } from '@core/states/state.module';
import { authInterceptor } from '@core/interceptors/auth.interceptor';
import { routes } from './app.routes';
import { provideNativeDateAdapter } from '@angular/material/core';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideNativeDateAdapter(),
    provideRouter(routes,
      withInMemoryScrolling({
        scrollPositionRestoration: "top",
      })
    ),
    provideHttpClient(withInterceptors([authInterceptor])),
    importProvidersFrom(
      PWAModule.forRoot(),
      StateModule.forRoot(),
    )
  ],
};
