import { StorageEngine } from '@ngxs/storage-plugin';
import { environment } from '@environments/environment';

export class StoragePlugin implements StorageEngine {
    /**
     * Gets length
     */
    get length(): number {
        return 0;
    }

    /**
     * Gets item
     * @param key
     * @returns item
     */
    getItem(key: string): any {
        return localStorage.getItem(environment.key + '-' + key);
    }

    /**
     * Sets item
     * @param key
     * @param val
     */
    setItem(key: string, val: any): void {
        localStorage.setItem(environment.key + '-' + key, val);
    }

    /**
     * Removes item
     * @param key
     */
    removeItem(key: string): void {
        localStorage.removeItem(environment.key + '-' + key);
    }

    /**
     * Clears storage plugin
     */
    clear(): void {
        localStorage.clear();
    }
}
