import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  styleUrls: [],
})

export class AppComponent implements OnInit {
  constructor() {
    
  }
  ngOnInit(): void {
  }
}
